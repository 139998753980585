/**
* Reset some basic elements
*/
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
	margin: 0;
	padding: 0;
}

.skip-links {
	background: #eee;
	color: #333;
	display: flex;
	justify-content: center;
	padding: 1em;
	position: absolute;
	top: -100em;

	&:focus-within {
		position: relative;
		top: 0;
	}

	a {
		margin-right: 1em;
	}
}

* {
	scrollbar-width: thin;
	scrollbar-color: #999 transparent;
}

body {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	background-color: white;
	color: #333;
	display: flex;
	flex-direction: column;
	font-family: $base-font-family;
	font-feature-settings: "kern" 1;
	font-size: 100%;
	font-smoothing: antialiased;
	font-kerning: normal;
	line-height: 1.375;
	min-height: 100vh;
	scroll-behavior: smooth;
	text-rendering: optimizeLegibility;
}


h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
	margin-bottom: $spacing-unit / 2;
}

h1,h2,h3,h4,h5,h6,
.h1,.h2,.h3,.h4,.h5,.h6 {
	color: #333;
}

h1, .h1 {
	font-size: 2.488rem;
}

h2, .h2 {
	font-size: 2.074rem;
}

h3, .h3 {
	font-size: 1.728rem;
}

h4, .h4 {
	font-size: 1.44rem;
}

h5, .h5 {
	font-size: 1.2rem;
}

h6, .h6 {
	font-size: 1rem;
}

blockquote {
	font-size: 1rem;
}

caption {
	font-size: 0.833rem;
}

figcaption {
	font-size: 0.833rem;
}

p {
	margin-bottom: 1em;
}

main {
	display: block;
}

img {
	max-width: 100%;
	vertical-align: middle;
}

// figure > img {
// 	display: block;
// }

ul, ol {
	margin-left: $spacing-unit;
}

li {
	> ul,
	> ol {
		margin-bottom: 0;
	}
}


h1, h2, h3, h4, h5, h6 {
	font-weight: $base-font-weight;
}


a {
	color: hsl(214, 76%, 50%);
	text-decoration: underline;

	&:visited {
		color: hsl(214, 76%, 40%);
	}

	&:hover {
		color: hsl(214, 76%, 60%);
	}
}

blockquote {
	color: $grey-color;
	border-left: 4px solid $grey-color-light;
	padding-left: $spacing-unit / 2;
	letter-spacing: -1px;
	font-style: italic;

	> :last-child {
		margin-bottom: 0;
	}
}

.wrapper {
	max-width: 65ch;
	margin: 0 auto;
	padding: 0 1em;
}

article {
	text-align: justify;
	text-align-last: left;
}
