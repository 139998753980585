/**
* Site header
*/
.site-header {
	border-bottom: 5px solid $grey-color-light;
	padding: 2.5em 0;
	position: relative;

	@include media-query($on-palm) {
		padding-left: 2em;
		padding-right: 2em;
	}
}

.site-title {
	display: block;
	margin: 0 auto;
	max-width: 100%;
	width: max-content;

	img {
		display: block;
	}

	&,
	&:visited {
		color: $grey-color-dark;
	}
}

.site-nav {
	.nav-trigger {
		display: none;
	}

	.menu-icon {
		display: none;
	}

	.page-link {
		color: $text-color;
		line-height: $base-line-height;

		// Gaps between nav items, but not on the last one
		&:not(:last-child) {
			margin-right: 20px;
		}
	}

	@include media-query($on-palm) {
		label[for="nav-trigger"] {
			background-color: $background-color;
			border: 1px solid $grey-color-light;
			border-radius: 5px;
			cursor: pointer;
			top: .5em;
			user-select: none;
			right: .5em;
			display: block;
			position: fixed;
			z-index: 11;
			cursor: pointer;
		}

		.menu-icon {
			align-items: center;
			display: flex;
			padding: .2em .5em;

			svg {
				display: block;
				width: 18px;
				height: 15px;
				margin-right: .2em;
				fill: $grey-color-dark;
			}
		}

		input ~ .trigger {
			align-items: center;
			background-color: rgba(white, 0.95);
			backdrop-filter: blur(0.1em);

			@supports(backdrop-filter: blur(0.25em)) {
				background-color: rgba(white, 0.5);
			}
			@supports(-webkit-backdrop-filter: blur(0.1em)) {
				-webkit-backdrop-filter: blur(0.25em);
				background-color: rgba(white, 0.7);
			}

			bottom: 0;
			clear: both;
			display: none;
			font-size: 2em;
			justify-content: center;
			left: 0;
			padding: 1em;
			position: fixed;
			right: 0;
			text-align: center;
			top: 0;
			z-index: 10;
		}

		input:checked ~ .trigger {
			animation: fadein .33s ease;
			animation-fill-mode: both;
			display: flex;
		}

		@keyframes fadein {
			from { opacity: 0;}

			to {opacity: 1;}
		}

		.page-link {
			display: block;
			padding: 5px 10px;

			&:not(:last-child) {
				margin-right: 0;
			}
			margin-left: 20px;
		}

		ul.nav {
			flex-direction: column;
			margin: 0;

			li {
				margin: 0;
			}
		}
	}
}

ul.nav {
	display: flex;
	justify-content: center;
	list-style: none;
	margin: 2.5em auto 0 auto;
	padding: 0;

	li {
		display: block;
		margin: 0 1.25em 0 0;

		&:last-child {
			margin: 0;
		}
	}
}


.site-footer {
	border-top: 5px solid $grey-color-light;
	color: $grey-color;
	padding: 2.5em 0;

	h2 {
		color: #333;
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}
}

.footer-col-wrapper {
	display: flex;
	flex-wrap: wrap;
}

.footer-col {
	flex: 1 0 auto;
	margin-right: 1em;

	&:last-child {
		margin-right: 0;
	}
}

.page-content {
	padding: 2em 0;
	flex: 1;
}
